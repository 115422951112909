import {Component, Inject, OnInit, AfterViewInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {environment} from '../../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {User} from '../../models/user.model';
import {AuthService} from '../../services/auth.service';
import {WhiteLabel} from '../../models/white-label.model';
import {WhiteLabelsService} from '../../services/white-labels.service';
import * as moment from 'moment';

@Component({
  selector: 'full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {
  user: User;
  whiteLabel: WhiteLabel;

  color: string;
  showMinisidebar = false;
  showDarktheme = false;

  showPoweredBy = environment.showPoweredBy;

  public config: PerfectScrollbarConfigInterface = {};

  year: number;

  constructor(public router: Router,
              private wlService: WhiteLabelsService,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.year = new Date().getFullYear();
    this._document.getElementById('appFavicon').setAttribute('href', environment.faviconLocation);

    this.user = AuthService.getAuthUser();
    this.whiteLabel = AuthService.getWhiteLabel();

    this.refreshWhiteLabel();
    this.removeHelpScoutBeacon();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.removeHelpScoutBeacon();
      }

      if (event instanceof NavigationEnd) {
        if (this.shouldDisplayHelpScoutBeacon(event)) {
          this.initHelpScoutBeacon();
        }
      }
    });
  }

  ngOnInit() {

  }

  afterViewInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard']);
    }
  }

  public refreshWhiteLabel() {
    this.wlService.read(this.user.white_label_id).subscribe(
      success => {
        this.whiteLabel = new WhiteLabel().deserialize(success);
        AuthService.setWhiteLabel(this.whiteLabel);

        this.color = this.whiteLabel.css_class;
      },
      error => {

      }
    );
  }

  private shouldDisplayHelpScoutBeacon(event: NavigationEnd) {
    return this.user.hasAllPermissions(['misc_help_scout_feature']) && !event.url.startsWith('/admin');
  }

  private initHelpScoutBeacon() {
    window.Beacon('init', '99561417-43be-47d2-baf5-817dacef3621');

    window.Beacon('config', {
      color: this.whiteLabel.primary,
    });

    window.Beacon('identify', {
      name: this.user.full_name,
      email: this.user.email,
      'Phone': this.user.phone,
      'Time Zone': this.user.time_zone,
      'Team Name': this.user.team ? this.user.team.name : null,
      'Plan': this.user.plan ? this.user.plan.name : null,
      'Join Date': moment(this.user.created_at).format('MMMM Do YYYY'),
    });
  }

  private removeHelpScoutBeacon() {
    window.Beacon('destroy');
  }
}
