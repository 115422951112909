import {Deserializable} from './deserializable.model';
import {TeamOwner} from './team-owner.model';
import {Address} from './address.model';

export class Team implements Deserializable {
  id: number;
  name: string;
  company_name: string;
  time_zone: string;
  white_label_id: number;
  status: string;
  contacts_count: number;
  tags_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  owner: TeamOwner;
  roles: string[];
  permissions: any;
  audiences: any[];
  content_categories: any[];
  primary_address: Address;
  users_count: number;
  user_slots: number;

  deserialize(input: any) {
    Object.assign(this, input);
    this.deserializeOwner(input.owner);
    this.deserializePrimaryAddress(input.primary_address);

    return this;
  }

  private deserializeOwner(input) {
    this.owner = new TeamOwner().deserialize(input);
  }

  private deserializePrimaryAddress(primary_address: object) {
    this.primary_address = new Address().deserialize(primary_address);
  }

  public hasAnyPermission(permissions: string[]) {
    for (const permission of permissions) {
      if (this.permissions && this.permissions[permission]) {
        return true;
      }
    }

    return false;
  }
}
