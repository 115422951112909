import {Deserializable} from './deserializable.model';
import {Profile} from './profile.model';
import {Address} from './address.model';
import {Social} from './social.model';
import {Team} from './team.model';
import {TeamSetting} from './team-setting.model';

export class User implements Deserializable {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  company_name: string;
  phone: string;
  dob: string;
  gender: string;
  time_zone: string;
  status: string;
  team_id: number;
  team: Team | null;
  plan_id: string;
  plan: any;
  white_label_id: number;
  can_see_white_label_users: boolean;
  cc_status: string;
  primary_address: Address;
  contacts_count: number;
  tags_count: number;
  roles: string[];
  permissions: any;
  audiences: any[];
  content_categories: any[];
  created_at: string;
  updated_at: string;
  deleted_at: string;

  deserialize(input: any) {
    Object.assign(this, input);
    if (input != null) {
      this.deserializePrimaryAddress(input.primary_address);
      this.deserializeTeam(input.team);
    }
    return this;
  }

  get addressFormatted() {
    if (this.primary_address) {
      return this.primary_address.formatted();
    }

    return null;
  }

  get isDeveloper() {
    return this.roles && this.roles.indexOf('Developer') > -1;
  }

  get isAdministrator() {
    return this.roles && this.roles.indexOf('Administrator') > -1;
  }

  get isLimitedAdministrator() {
    return this.roles && this.roles.indexOf('Limited Administrator') > -1;
  }

  get isTeamAdministrator() {
    return this.roles && this.roles.indexOf('Team Administrator') > -1;
  }

  public isTeamUser() {
    return !!this.team_id;
  }

  public hasAnyPermission(permissions: string[]) {
    if (!this.permissions) {
      return false;
    }

    for (const permission of permissions) {
      if (this.permissions[permission]) {
        return true;
      }
    }

    return false;
  }

  public hasAllPermissions(permissions: string[]) {
    if (!this.permissions) {
      return false;
    }

    for (const permission of permissions) {
      if (!this.permissions[permission]) {
        return false;
      }
    }

    return true;
  }

  private deserializePrimaryAddress(primary_address: object) {
    this.primary_address = new Address().deserialize(primary_address);
  }

  private deserializeTeam(team: object) {
    this.team = null;

    if (team) {
      this.team = new Team().deserialize(team);
    }
  }
}
