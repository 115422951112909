import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user.model';
import {environment} from '../../environments/environment';
import {buildUrl} from 'build-url';
import {WhiteLabel} from '../models/white-label.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private static authURL = environment.authURL;
  private static apiURL = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  public static logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth');
    localStorage.removeItem('user');
    localStorage.removeItem('uid');

    window.location.href = buildUrl(AuthService.authURL, {
      path: `logout`,
      hash: '',
      queryParams: null
    });
  }

  public static getAccessToken() {
    const token = localStorage.getItem('access_token');
    if (token == null) {
      this.logout();
      return;
    }

    return localStorage.getItem('access_token');
  }

  public static getCurrentUser() {
    const user = localStorage.getItem('user');
    if (user == null) {
      this.logout();
      return;
    }

    return new User().deserialize(JSON.parse(user));
  }

  public static getWhiteLabel() {
    const wl = localStorage.getItem('wl');
    if (wl == null) {
      this.logout();
      return;
    }

    return new WhiteLabel().deserialize(JSON.parse(wl));
  }

  public static getCurrentTeam() {
    const user = localStorage.getItem('user');
    if (user == null) {
      this.logout();
      return;
    }

    return new User().deserialize(JSON.parse(user)).team;
  }

  public static getAuthUser() {
    const user = localStorage.getItem('auth');
    if (user == null) {
      this.logout();
      return;
    }

    return new User().deserialize(JSON.parse(localStorage.getItem('auth')));
  }

  public static setAuthUser(input) {
    localStorage.setItem('auth', JSON.stringify(input));
  }

  public static setCurrentUser(input) {
    localStorage.setItem('user', JSON.stringify(input));
    localStorage.setItem('uid', `${input.id}`);
  }

  public static setWhiteLabel(input) {
    localStorage.setItem('wl', JSON.stringify(input));
  }

  private static setAccessToken(token) {
    localStorage.setItem('access_token', token);
  }

  public login(token: string, userId?: number) {
    AuthService.setAccessToken(token);

    if (userId == null) {
      return this.http.get<User>(buildUrl(environment.apiURL, {
        path: `me`,
        hash: null,
        queryParams: {with: 'plan,team,roles,permissions,addresses'}
      }));
    } else {
      return this.http.get<User>(buildUrl(environment.apiURL, {
        path: `users/${userId}`,
        hash: null,
        queryParams: {with: 'plan,team,roles,permissions,addresses'}
      }));
    }
  }

  public retrieveAuthUser() {
    return this.http.get<User>(buildUrl(environment.apiURL, {
      path: `me`,
      hash: null,
      queryParams: {with: 'plan,team,roles,permissions,addresses'}
    }));
  }
}
