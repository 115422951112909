import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('access_token') === null) {
      AuthService.logout();
    }

    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401 || err.status === 503) {
        // logout if 401/503 response returned from api
        AuthService.logout();
      }

      if (err.status === 403) { // Access to whatever you're trying to access is forbidden. Bye bye
        AuthService.logout();
      }

      const error = err.error || err.statusText;
      return throwError(error);
    }));
  }
}
