import {Injectable} from '@angular/core';
import {PaginatedWhiteLabels} from '../models/paginated-white-labels.model';
import {environment} from '../../environments/environment';
import {WhiteLabel} from '../models/white-label.model';
import {HttpClient} from '@angular/common/http';
import {buildUrl} from 'build-url';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})

export class WhiteLabelsService {

  constructor(private http: HttpClient) {
  }

  public paginate(filter: object) {
    return this.http.get<PaginatedWhiteLabels>(buildUrl(environment.apiURL, {
      path: `admin/white_labels`,
      hash: '',
      queryParams: filter
    }));
  }

  public create(attributes: any) {
    return this.http.post<WhiteLabel>(buildUrl(environment.apiURL, {
      path: `admin/white_labels`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public read(site: number) {
    return this.http.get<WhiteLabel>(buildUrl(environment.apiURL, {
      path: `admin/white_labels/${site}`,
      hash: '',
      queryParams: null
    }));
  }

  public update(site: number, attributes: any) {
    return this.http.put<WhiteLabel>(buildUrl(environment.apiURL, {
      path: `admin/white_labels/${site}`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public users(site: number) {
    return this.http.get<User[]>(buildUrl(environment.apiURL, {
      path: `admin/white_labels/${site}/users`,
      hash: '',
      queryParams: null
    }));
  }
}
