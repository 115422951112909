import {environment} from '../../environments/environment';

declare const Pusher: any;
import {Injectable} from '@angular/core';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})

export class PusherService {
  pusher: any;
  private channel: any;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
  }

  public subscribeUser(user: User) {
    if (!this.channel) {
      this.channel = this.pusher.subscribe(`user.${user.id}`);
    }

    return this.channel;
  }
}
