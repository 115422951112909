import {Deserializable} from './deserializable.model';
import {TourStep} from './tour-step.model';

export class TourSteps implements Deserializable {
  steps: TourStep[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
