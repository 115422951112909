import {Deserializable} from './deserializable.model';

export class Address implements Deserializable {
  id: number;
  name: string | null;
  street: string | null;
  street_2: string | null;
  city: string | null;
  state: string | null;
  country_id: string | null;
  country_name: string | null;
  postal_code: string | null;
  latitude: number | null;
  longitude: number | null;
  is_primary: boolean | null;
  type: string;
  created_at: string;
  updated_at: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

  formatted() {
    let output = '';

    if (this.street) {
      output += `${this.street}`;
    }

    if (this.street_2) {
      output += `${this.street_2}`;
    }

    if (this.city) {
      if (output.length) {
        output += ', ';
      }
      output += `${this.city}`;
    }

    if (this.state) {
      if (output.length) {
        output += ', ';
      }
      output += `${this.state}`;
    }

    if (this.country_name) {
      if (output.length) {
        output += ', ';
      }
      output += `${this.country_name}`;
    }

    if (this.postal_code) {
      if (output.length) {
        output += ', ';
      }
      output += `${this.postal_code}`;
    }

    output = output.trim();

    if (output) {
      return output;
    }

    return null;
  }
}
