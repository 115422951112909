import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';

export const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './pages/auth/auth.module#AuthModule'
      },
      {
        path: 'sign-in',
        loadChildren: './pages/sign-in/sign-in.module#SignInModule'
      },
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'admin',
        loadChildren: './pages/admin/admin.module#AdminModule'
      },
      {
        path: 'audiences',
        loadChildren: './pages/audiences/audiences.module#AudiencesModule'
      },
      {
        path: 'audience_emails',
        loadChildren: './pages/audience-emails/audience-emails.module#AudienceEmailsModule'
      },
      {
        path: 'blasts',
        loadChildren: './pages/blasts/blasts.module#BlastsModule'
      },
      {
        path: 'callback',
        loadChildren: './pages/callback/callback.module#CallbackModule'
      },
      {
        path: 'cards',
        loadChildren: './pages/cards/cards.module#CardsModule'
      },
      {
        path: 'gifts',
        loadChildren: './pages/gifts/gifts.module#GiftsModule'
      },
      {
        path: 'campaigns',
        loadChildren: './pages/campaigns/campaigns.module#CampaignsModule'
      },
      {
        path: 'contacts',
        loadChildren: './pages/contacts/contacts.module#ContactsModule'
      },
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'forms',
        loadChildren: './pages/forms/custom-forms.module#CustomFormsModule'
      },
      {
        path: 'fields',
        loadChildren: './pages/fields/fields.module#FieldsModule'
      },
      {
        path: 'hot_sheets',
        loadChildren: './pages/hot-sheets/hot-sheets.module#HotSheetsModule'
      },
      {
        path: 'integrations',
        loadChildren: './pages/integrations/integrations.module#IntegrationsModule'
      },
      {
        path: 'notifications',
        loadChildren: './pages/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'reporting',
        loadChildren: './pages/reporting/reporting.module#ReportingModule'
      },
      {
        path: 'signatures',
        loadChildren: './pages/signatures/signatures.module#SignaturesModule'
      },
      {
        path: 'social_messages',
        loadChildren: './pages/social-messages/social-messages.module#SocialMessagesModule'
      },
      {
        path: 'sites',
        loadChildren: './pages/sites/sites.module#SitesModule'
      },
      {
        path: 'support',
        loadChildren: './pages/support/support.module#SupportModule'
      },
      {
        path: 'tags',
        loadChildren: './pages/tags/tags.module#TagsModule'
      },
      {
        path: 'teams',
        loadChildren: './pages/team/team.module#TeamModule'
      },
      {
        path: 'text_messages',
        loadChildren: './pages/text-messages/text-messages.module#TextMessagesModule'
      },
      {
        path: 'templated_emails',
        loadChildren: './pages/templated-emails/templated-emails.module#TemplatedEmailsModule'
      },
      {
        path: 'academies',
        loadChildren: './pages/academies/academies.module#AcademiesModule'
      },
      {
        path: 'training_videos',
        loadChildren: './pages/training-videos/training-videos.module#TrainingVideosModule'
      },
      {
        path: 'users',
        loadChildren: './pages/users/users.module#UsersModule'
      },
      {
        path: 'voicemails',
        loadChildren: './pages/voicemails/voicemails.module#VoicemailsModule'
      },
      {
        path: 'webinars',
        loadChildren: './pages/webinars/webinars.module#WebinarsModule'
      },
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
  exports: [RouterModule]
})

export class AppRoutingModule {
}

