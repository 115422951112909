import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileImageComponent} from '../../components/profile-image/profile-image.component';
import {AvatarModule, AvatarSource} from 'ngx-avatar';

const avatarSourcesOrder = [AvatarSource.GRAVATAR, AvatarSource.INITIALS, AvatarSource.CUSTOM];
const avatarColors = ['#c5c5c5'];


@NgModule({
  imports: [
    CommonModule,
    AvatarModule.forRoot({
      sourcePriorityOrder: avatarSourcesOrder,
      colors: avatarColors,
    }),
  ],
  declarations: [
    ProfileImageComponent,
  ],
  exports: [
    ProfileImageComponent,
  ],
  entryComponents: [
    ProfileImageComponent,
  ]
})

export class ProfileImageModule {
}
