import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute, NavigationStart, RoutesRecognized} from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {HgTourService} from '../../services/hg-tour.service';
import {User} from '../../models/user.model';
import {AuthService} from '../../services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html'
})

export class BreadcrumbComponent implements OnInit {
  tourLoading = false;
  user: User;

  @Input() layout;
  pageInfo: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tourService: HgTourService
  ) {

    let activeRoute = this.router.routerState.root;
    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }

    activeRoute.data.subscribe((event) => {
      this.titleService.setTitle(event['title']);
      this.pageInfo = event;
    });

    this
      .router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe((event) => {
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });

    this.user = AuthService.getCurrentUser();
  }

  ngOnInit() {

  }

  restartTour(key: string) {
    this.tourLoading = true;
    this.tourService.restartTour(key).subscribe(success => {
      this.tourService.startTour(key);
      this.tourLoading = false;
    });
  }
}
