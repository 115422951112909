import {Address} from './address.model';
import {Social} from './social.model';
import {Profile} from './profile.model';
import {Deserializable} from './deserializable.model';

export class TeamOwner implements Deserializable {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  company_name: string;
  phone: string;
  dob: string;
  gender: string;
  time_zone: string;
  status: string;
  subscription_status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  contacts_count: number;
  tags_count: number;
  primary_address: Address;
  social_fields: Social;
  custom_fields: Profile;
  roles: string[];
  permissions: any;
  audiences: any[];
  content_categories: any[];
  plan: any;

  deserialize(input: any) {
    Object.assign(this, input);
    if (input != null) {
      this.deserializePrimaryAddress(input.primary_address);
    }
    return this;
  }

  get addressFormatted() {
    if (this.primary_address) {
      return this.primary_address.formatted();
    }

    return null;
  }

  get isDeveloper() {
    return this.roles.indexOf('Developer') > -1;
  }

  get isAdministrator() {
    return this.roles.indexOf('Administrator') > -1;
  }

  get isTeamAdministrator() {
    return this.roles.indexOf('Team Administrator') > -1;
  }

  public hasAnyPermission(permissions: string[]) {
    for (const permission of permissions) {
      if (this.permissions && this.permissions[permission]) {
        return true;
      }
    }

    return false;
  }

  private deserializePrimaryAddress(primary_address: object) {
    this.primary_address = new Address().deserialize(primary_address);
  }
}
