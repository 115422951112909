import {Deserializable} from './deserializable.model';

export class WhiteLabel implements Deserializable {
  id: number;
  name: string;
  description: string;
  subdomain: string;
  status: string;
  meta: {
    images: {
      logo: string,
      logo_small: string,
      logo_white: string,
      logo_white_small: string,
    };
    general: {
      theme_color: string;
      theme_color_alt: string;

      top_bar: string;

      sidebar: string;
      sidebar_alt: string;
      sidebar_text: string;
      sidebar_icons: string;

      heading_text: string;

      body_color: string;
      body_text: string;

      light_text: string;
    };
    colors: {
      primary: string;
      primary_dark: string;
      primary_light: string;

      danger: string;
      danger_dark: string;
      danger_light: string;

      warning: string;
      warning_dark: string;
      warning_light: string;

      success: string;
      success_dark: string;
      success_light: string;

      info: string;
      info_dark: string;
      info_light: string;

      white: string;
      red: string;
      yellow: string;
      purple: string;
      blue: string;
      green: string;
      orange: string;
    }
  };

  theme_color: string;
  theme_color_alt: string;

  top_bar: string;

  sidebar: string;
  sidebar_alt: string;
  sidebar_text: string;
  sidebar_icons: string;

  heading_text: string;

  body_color: string;
  body_text: string;

  light_text: string;

  primary: string;
  primary_dark: string;
  primary_light: string;

  danger: string;
  danger_dark: string;
  danger_light: string;

  warning: string;
  warning_dark: string;
  warning_light: string;

  success: string;
  success_dark: string;
  success_light: string;

  info: string;
  info_dark: string;
  info_light: string;

  white: string;
  red: string;
  yellow: string;
  purple: string;
  blue: string;
  green: string;
  orange: string;

  logo: string;
  logo_small: string;
  logo_white: string;
  logo_white_small: string;

  full_url: string;
  css_class: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  users_count: number;
  scss_content: string;
  environment_content: string;

  public static defaultMetaObject() {
    return {
      images: {
        logo: null,
        logo_small: null,
        logo_white: null,
        logo_white_small: null,
      },
      general: {
        theme_color: '#6fbd46',
        theme_color_alt: '#f45d00',

        top_bar: '#6fbd46',

        sidebar: '#ffffff',
        sidebar_alt: '#e8e8e8',
        sidebar_text: '#424242',
        sidebar_icons: '#6fbe46',

        heading_text: '#414041',

        body_color: '#eeeeee',
        body_text: '#414041',

        light_text: '#a6b7bf',
      },
      colors: {
        primary: '#6fbd46',
        primary_dark: '#518a33',
        primary_light: '#96cf78',

        danger: '#dc3545',
        danger_dark: '#a12733',
        danger_light: '#f9e7eb',

        warning: '#ffc107',
        warning_dark: '#ba8d06',
        warning_light: '#fff8ec',

        success: '#28a745',
        success_dark: '#1e7a33',
        success_light: '#e8fdeb',

        info: '#1976d2',
        info_dark: '#135699',
        info_light: '#cfecfe',

        white: '#ffffff',
        red: '#fb3a3a',
        yellow: '#ffc107',
        purple: '#7460ee',
        blue: '#1976d2',
        green: '#28a745',
        orange: '#fd7e14',
      }
    };
  }

  deserialize(input: any) {
    Object.assign(this, input);

    if (!this.meta) {
      this.meta = WhiteLabel.defaultMetaObject();
    }

    return this;
  }
}
