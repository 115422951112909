import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {buildUrl} from 'build-url';
import {HttpClient} from '@angular/common/http';
import {TourSteps} from '../models/tour-steps.model';
import {User} from '../models/user.model';
import {AuthService} from './auth.service';
import * as introJs from 'intro.js/intro.js';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class HgTourService {
  private user: User;

  private apiURL = environment.apiURL;

  private tourPage: string;

  private stepData = {
    title: '',
    text: '',
  };

  private tourSteps: TourSteps;

  introJS = introJs();

  constructor(private http: HttpClient) {
    this.user = AuthService.getCurrentUser();
  }

  public stepTitle() {
    return this.stepData.title;
  }

  public stepText() {
    return this.stepData.text;
  }

  public startTour(pageKey: string) {
    this.tourPage = pageKey;

    this.getUserTourSteps(pageKey).subscribe(
      success => {
        this.tourSteps = new TourSteps().deserialize(success);

        if (!this.tourSteps.steps.length) {
          return;
        }

        const that = this;

        const introSteps = this.tourSteps.steps.map(function (tourStep) {
          return {
            element: document.querySelector('.' + tourStep.element),
            intro: tourStep.full_text,
            position: 'auto',
            scrollTo: 'tooltip',
          };
        });

        if (!introSteps.length) {
          return;
        }

        this.applyFullPageCss();

        this.introJS.setOptions({
          steps: introSteps,
          nextLabel: 'Next',
          prevLabel: 'Prev',
          hidePrev: true,
          hideNext: false,
          exitOnOverlayClick: false,
          showStepNumbers: false,
          showBullets: false,
          disableInteraction: true,
          scrollToElement: true,
        }).onexit(function () {
          that.completeTour();
          that.removeFullPageCss();
        }).start();
      }
    );
  }

  public restartTour(page: string) {
    return this.http.post(buildUrl(this.apiURL, {
      path: `users/${this.user.id}/tour_steps/${page}/restart`,
      hash: '',
      queryParams: null
    }), {});
  }

  public indexPages() {
    return this.http.get(buildUrl(this.apiURL, {
      path: `tour_pages`,
      hash: '',
      queryParams: {status: 'Enabled'}
    }));
  }

  public updateElement(step: any, attributes: any) {
    return this.http.put(buildUrl(this.apiURL, {
      path: `tour_steps/${step.id}`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public moveStepUp(step: any) {
    return this.http.put(buildUrl(this.apiURL, {
      path: `tour_steps/${step.id}/move_up`,
      hash: '',
      queryParams: null
    }), []);
  }

  public moveStepDown(step: any) {
    return this.http.put(buildUrl(this.apiURL, {
      path: `tour_steps/${step.id}/move_down`,
      hash: '',
      queryParams: null
    }), []);
  }

  public indexTourSteps(pageKey: string) {
    return this.http.get(buildUrl(this.apiURL, {
      path: `tour_pages/${pageKey}/steps`,
      hash: '',
      queryParams: null,
    }));
  }

  private getUserTourSteps(pageKey: string) {
    return this.http.get(buildUrl(this.apiURL, {
      path: `users/${this.user.id}/tour_steps/${pageKey}`,
      hash: '',
      queryParams: {status: 'Enabled'}
    }));
  }

  private completeTour() {
    return this.http.put(buildUrl(this.apiURL, {
      path: `users/${this.user.id}/tour_steps/${this.tourPage}/complete`,
      hash: '',
      queryParams: null
    }), {}).subscribe(success => {
      // ...
    });
  }

  private applyFullPageCss() {
    $(function () {
      $('.left-sidebar').addClass('left-sidebar-fp');
      $('.scroll-sidebar').addClass('scroll-sidebar-fp');
      $('.page-wrapper').addClass('page-wrapper-fp');
      $('.footer').addClass('d-none');
    });
  }

  private removeFullPageCss() {
    $('.left-sidebar').removeClass('left-sidebar-fp');
    $('.scroll-sidebar').removeClass('scroll-sidebar-fp');
    $('.page-wrapper').removeClass('page-wrapper-fp');
    $('.footer').removeClass('d-none');
  }
}
