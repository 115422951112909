import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector:    'blank-layout',
  templateUrl: './blank.component.html',
  styles:      []
})

export class BlankComponent {
  year: number;
  color: string;
  showMinisidebar = false;
  showDarktheme = false;

  showPoweredBy = environment.showPoweredBy;

  constructor(public router: Router) {
    this.color = environment.color;
    this.year = new Date().getFullYear();
  }
}
