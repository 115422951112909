import {RouteInfo} from './sidebar.metadata';

export const TEAM_ADMIN_ROUTES: RouteInfo[] = [
  // Dashboard
  {
    title: 'Team Dashboard',
    path: '/teams/:teamId/dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Team Training Videos',
    path: '/teams/:teamId/training_videos',
    icon: 'mdi mdi-video',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['teams_training_videos_feature'],
    submenu: [],
  },
  {
    title: 'Team Academies',
    path: '/teams/:teamId/academies',
    icon: 'mdi mdi-school',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['teams_academies_feature'],
    submenu: [],
  },
  // Management
  {
    title: 'Team Management',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Contacts',
    path: '/teams/:teamId/contacts/index',
    icon: 'mdi mdi-account-multiple-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [
      {
        title: 'Index',
        path: '/teams/:teamId/contacts/index',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Upload',
        path: '/teams/:teamId/contacts/upload',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      }
    ]
  },
  {
    title: 'Tags & Audiences',
    path: '/teams/:teamId/tags',
    icon: 'mdi mdi-tag-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [
      {
        title: 'Team Tags',
        path: '/teams/:teamId/tags',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'User Tags',
        path: '/teams/:teamId/user_tags',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'User Audiences',
        path: '/teams/:teamId/user_audiences',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      }
    ]
  },
  {
    title: 'Signatures',
    path: '/teams/:teamId/signatures',
    icon: 'mdi mdi-account-card-details',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Users',
    path: '/teams/:teamId/users',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  // Messaging
  {
    title: 'Team Messaging',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Blast Messages',
    path: '/teams/:teamId/blasts',
    icon: 'mdi mdi-meteor',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Campaigns',
    path: '/teams/:teamId/campaigns',
    icon: 'mdi mdi-account-convert',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [
      {
        title: 'Team Reporting',
        path: '/teams/:teamId/campaigns/reporting',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
    ]
  },
  {
    title: 'Message Libraries',
    path: '/teams/:teamId/libraries',
    icon: 'mdi mdi-meteor',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['teams_message_library_feature'],
    submenu: [],
  },
  // Misc
  {
    title: 'Misc',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Reporting',
    path: '/teams/:teamId/reporting',
    icon: 'mdi mdi-numeric',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Hot Sheets',
    path: '/teams/:teamId/hot_sheets',
    icon: 'mdi mdi-fire',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Settings',
    path: '/teams/:teamId/settings',
    icon: 'mdi mdi-settings',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
];
