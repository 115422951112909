import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {buildUrl} from 'build-url';

@Injectable({
  providedIn: 'root'
})

export class ApiRouteService {
  private authURL: string;
  private apiURL: string;

  constructor() {
    this.authURL = environment.authURL;
    this.apiURL = environment.apiURL;
  }

  // region Users

  public indexUsers(filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users`,
      hash: '',
      queryParams: filter
    });
  }

  public indexTeamUsers(team, params = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/users`,
      hash: '',
      queryParams: params
    });
  }

  public viewUser(user: number) {
    return `${this.apiURL}/users/${user}`;
  }

  // endregion

  public viewUserBilling(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/billing`,
      hash: '',
      queryParams: filter
    });
  }

  // region User Audits

  public indexUserAudits(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  public indexUserNotes(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/notes`,
      hash: '',
      queryParams: filter
    });
  }

  public indexContactNotes(contact: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `contacts/${contact}/notes`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  public indexUserRolesAndPermissions(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/permissions`,
      hash: '',
      queryParams: null
    });
  }

  public getUserEmailTemplate(user: number, type: string, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/email_template/${type}`,
      hash: '',
      queryParams: filter
    });
  }

  public getRecipientsCount(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/recipient_count`,
      hash: '',
      queryParams: params
    });
  }

  public postUserBlastEmail(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/blast_emails`,
      hash: '',
    });
  }

  public postUserAudienceEmail(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audience_emails`,
      hash: '',
    });
  }

  public postUserTemplatedEmail(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/templated_emails`,
      hash: '',
    });
  }

  public postUserCampaign(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/campaigns`,
      hash: '',
    });
  }

  public indexUserBlastEmails(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/blast_emails`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserAudienceMessages(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audience_messages`,
      hash: '',
      queryParams: params
    });
  }

  public rateUserAudienceMessages(user: number, message: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audience_messages/${message}/rate`,
    });
  }

  public rateUserTemplatedMessages(user: number, message: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/templated_messages/${message}/rate`,
    });
  }

  public indexUserTemplatedMessages(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/templated_messages`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserSocialMessages(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/social_messages`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserAudienceEmails(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audience_emails`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserTemplatedEmails(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/templated_emails`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserCampaigns(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/campaigns`,
      hash: '',
      queryParams: params
    });
  }

  public indexUserHotSheets(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/hot_sheets`,
      hash: '',
      queryParams: params
    });
  }

  public viewUserHotSheet(user: number, date: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/hot_sheets/${date}`,
      hash: '',
      queryParams: null
    });
  }

  // region User Forms

  public filterUserRecipients(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/recipients`,
      hash: '',
      queryParams: filter
    });
  }

  public viewUserUpdateForm(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/forms/user`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region User Contacts

  public indexUserContacts(user: number, queryParams: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/contacts`,
      hash: '',
      queryParams: queryParams
    });
  }

  public indexTeamContacts(team: number, queryParams: object) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/contacts`,
      hash: '',
      queryParams: queryParams
    });
  }

  public indexUserContactUploads(user: number, queryParams: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/uploads`,
      hash: '',
      queryParams: queryParams
    });
  }

  public updateContactUpload(upload: number) {
    return buildUrl(this.apiURL, {
      path: `uploads/${upload}`,
      hash: '',
    });
  }

  public indexTeamContactUploads(team: number, queryParams: object = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/uploads`,
      hash: '',
      queryParams: queryParams
    });
  }

  public createUserContact(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/contacts`,
      hash: '',
      queryParams: null
    });
  }

  public createUserContactUpload(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/uploads`,
      hash: '',
      queryParams: null
    });
  }

  public createTeamContactUpload(team: number) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/uploads/Contact`,
      hash: '',
      queryParams: null
    });
  }

  // endregion

  public createUserAddress(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/addresses`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsTotalContacts(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/contacts/total`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsContacts(user: number, timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/contacts/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsTotalTags(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/tags/total`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsTags(user: number, timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/tags/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsTotalSends(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/sent/total`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsSends(user: number, timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/sent/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public userMetricsReporting(user: number, timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/reporting/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public userMostEngagedContacts(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/metrics/most_engaged/all_time`,
      hash: '',
      queryParams: null
    });
  }

  // admin metrics

  public adminMetricsTotalContacts() {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/contacts/total`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsContacts(timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/contacts/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsTotalTags() {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/tags/total`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsTags(timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/tags/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsTotalSends() {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/sent/total`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsSends(timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/sent/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public adminMetricsReporting(timeFrame: string) {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/reporting/${timeFrame}`,
      hash: '',
      queryParams: null
    });
  }

  public adminMostEngagedUsers() {
    return buildUrl(this.apiURL, {
      path: `admin/metrics/most_engaged/all_time`,
      hash: '',
      queryParams: null
    });
  }

  // region User Tags

  public indexUserTags(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/tags`,
      hash: '',
      queryParams: filter
    });
  }

  public indexTeamTags(team: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/tags`,
      hash: '',
      queryParams: filter
    });
  }

  public createUserTag(user: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/tags`,
      hash: '',
      queryParams: null
    });
  }

  public createTeamTag(team: number) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/tags`,
      hash: '',
      queryParams: null
    });
  }

  public createUserJob(user: number, type: string) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/jobs/${type}`,
      hash: '',
      queryParams: null
    });
  }

  public viewUserTag(user: number, tag: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/tags/${tag}`,
      hash: '',
      queryParams: null
    });
  }

  public viewTeamTag(team: number, tag: number) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/tags/${tag}`,
      hash: '',
      queryParams: null
    });
  }

  public updateUserTag(user: number, tag: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/tags/${tag}`,
      hash: '',
      queryParams: null
    });
  }

  public deleteUserTag(user: number, tag: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/tags/${tag}`,
      hash: '',
      queryParams: null
    });
  }

  // endregion

  public viewVoicemailDrop(voicemail: number) {
    return buildUrl(this.apiURL, {
      path: `voicemail_drops/${voicemail}`,
      hash: '',
      queryParams: null
    });
  }

  public viewCardMail(card: number) {
    return buildUrl(this.apiURL, {
      path: `post_cards/${card}`,
      hash: '',
      queryParams: null
    });
  }

  public indexUserCards(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/post_cards`,
      hash: '',
      queryParams: params
    });
  }

  public indexCardFonts() {
    return buildUrl(this.apiURL, {
      path: `post_card_fonts`,
      hash: '',
      queryParams: null
    });
  }

  public indexCardCategories() {
    return buildUrl(this.apiURL, {
      path: `post_card_categories`,
      hash: '',
      queryParams: null
    });
  }

  public indexCards(category = null) {
    return buildUrl(this.apiURL, {
      path: `post_card_library/${category}`,
      hash: '',
      queryParams: null
    });
  }


  public indexUserVoicemailDrops(user: number, params: object) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/voicemail_drops`,
      hash: '',
      queryParams: params
    });
  }


  public indexUserForms(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/forms`,
      hash: '',
      queryParams: filter
    });
  }

  public viewForm(form: number) {
    return buildUrl(this.apiURL, {
      path: `forms/${form}`,
      hash: '',
      queryParams: null
    });
  }

  public viewBlastEmail(email: number) {
    return buildUrl(this.apiURL, {
      path: `blast_emails/${email}`,
      hash: '',
      queryParams: null
    });
  }

  public viewAudienceEmail(email: number) {
    return buildUrl(this.apiURL, {
      path: `audience_emails/${email}`,
      hash: '',
      queryParams: null
    });
  }

  public viewCampaign(id: number) {
    return buildUrl(this.apiURL, {
      path: `campaigns/${id}`,
      hash: '',
      queryParams: null
    });
  }


  public viewTemplatedEmail(email: number) {
    return buildUrl(this.apiURL, {
      path: `templated_emails/${email}`,
      hash: '',
      queryParams: null
    });
  }

  public indexUserFields(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/fields`,
      hash: '',
      queryParams: filter
    });
  }

  public viewUserField(user: number, field: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/fields/${field}`,
      hash: '',
      queryParams: null
    });
  }

  // region User Audiences

  public indexUserAudiences(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audiences`,
      hash: '',
      queryParams: filter
    });
  }

  public indexUserContentCategories(user: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/content_categories`,
      hash: '',
      queryParams: filter
    });
  }

  public indexTeamAudiences(team: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/audiences`,
      hash: '',
      queryParams: filter
    });
  }

  public viewUserAudience(user: number, audience: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audiences/${audience}`,
      hash: '',
      queryParams: null
    });
  }

  public editUserAudience(user: number, audience: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audiences/${audience}`,
      hash: '',
      queryParams: null
    });
  }

  public viewTeamAudience(team: number, audience: number) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/audiences/${audience}`,
      hash: '',
      queryParams: null
    });
  }

  public updateUserAudience(user: number, audience: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audiences/${audience}`,
      hash: '',
      queryParams: null
    });
  }

  public deleteUserAudience(user: number, audience: number) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/audiences/${audience}`,
      hash: '',
      queryParams: null
    });
  }

  // endregion

  // region User Forms

  public userContactCreateForm(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/forms/contact`,
      hash: '',
      queryParams: null
    });
  }

  // endregion

  // region User Media

  public userMediaCreate(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/media`,
      hash: '',
      queryParams: null
    });
  }

  public indexUserMedia(user, filter: any) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/media`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region User Signatures

  public userSignatureTemplates(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/signature_templates`,
      hash: '',
      queryParams: null
    });
  }

  public userDefaultSignature(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/signature`,
      hash: '',
      queryParams: null
    });
  }

  public setUserDefaultSignature(user, signature) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/signature/${signature}`,
      hash: '',
      queryParams: null
    });
  }

  public userSignatures(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/signatures`,
      hash: '',
    });
  }

  public userAddresses(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/addresses`,
      hash: '',
    });
  }

  public userForwardingEmails(user) {
    return buildUrl(this.apiURL, {
      path: `users/${user}/forwarding_emails`,
      hash: '',
    });
  }

  public teamSignatures(team, filter: any = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/signatures`,
      hash: '',
      queryParams: filter
    });
  }

  public teamBlasts(team, filter: any = null) {
    return buildUrl(this.apiURL, {
      path: `teams/${team}/blasts`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region Contacts

  public indexContacts(filter: object = null) {
    return `${this.apiURL}/contacts`;
  }

  public viewContact(contact: number) {
    return `${this.apiURL}/contacts/${contact}`;
  }

  // endregion

  // region Contact Forms

  public viewContactUpdateForm(contact: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `contacts/${contact}/form`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region Contact Audits

  public indexContactAudits(contact: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `contacts/${contact}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region Tags

  public indexTags(filter: object = null) {
    return `${this.apiURL}/tags`;
  }

  public viewTag(tag: number) {
    return `${this.apiURL}/tags/${tag}`;
  }

  public editTag(tag: number) {
    return `${this.apiURL}/tags/${tag}`;
  }

  public deleteTag(tag: number) {
    return `${this.apiURL}/tags/${tag}`;
  }

  // endregion

  // region Signatures

  public readSignature(signature: number) {
    return `${this.apiURL}/signatures/${signature}`;
  }

  public editSignature(signature: number) {
    return `${this.apiURL}/signatures/${signature}`;
  }

  public deleteSignature(signature: number) {
    return `${this.apiURL}/signatures/${signature}`;
  }

  // endregion

  // region Tag Audits

  public indexTagAudits(tag: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `tags/${tag}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  // region Audiences

  public indexAudiences(filter: object = null) {
    return `${this.apiURL}/audiences`;
  }

  public indexContentCategories(filter: object = null) {
    return `${this.apiURL}/content_categories`;
  }

  public viewAudience(audience: number) {
    return `${this.apiURL}/audiences/${audience}`;
  }

  public editAudience(audience: number) {
    return `${this.apiURL}/audiences/${audience}`;
  }

  public deleteAudience(audience: number) {
    return `${this.apiURL}/audiences/${audience}`;
  }

  // endregion

  public editField(field: number) {
    return `${this.apiURL}/fields/${field}`;
  }

  public editBlastEmail(blastEmail: number) {
    return `${this.apiURL}/blast_emails/${blastEmail}`;
  }

  public editAudienceEmail(email: number) {
    return `${this.apiURL}/audience_emails/${email}`;
  }

  public editCampaign(id: number) {
    return `${this.apiURL}/campaigns/${id}`;
  }

  public indexCampaignTouches(campaignId: number) {
    return `${this.apiURL}/campaigns/${campaignId}/touches`;
  }

  public updateCampaignTouch(campaignId: number, touchId) {
    return `${this.apiURL}/campaigns/${campaignId}/touches/${touchId}`;
  }

  public editTemplatedEmail(email: number) {
    return `${this.apiURL}/templated_emails/${email}`;
  }


  // region Audience Audits

  public indexAudienceAudits(audience: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `audiences/${audience}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  // endregion

  public indexFieldAudits(field: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `fields/${field}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  public indexBlastEmailAudits(blastEmail: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `blast_emails/${blastEmail}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  public indexAudienceEmailAudits(email: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `audience_emails/${email}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  public indexAudienceEmailRecipients(email: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `audience_emails/${email}/recipients`,
      hash: '',
      queryParams: filter
    });
  }

  public indexTemplatedEmailRecipients(email: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `templated_emails/${email}/recipients`,
      hash: '',
      queryParams: filter
    });
  }

  public indexBlastEmailRecipients(email: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `blast_emails/${email}/recipients`,
      hash: '',
      queryParams: filter
    });
  }

  public indexCampaignTouchRecipients(campaign: number, touch: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `campaigns/${campaign}/touches/${touch}/recipients`,
      hash: '',
      queryParams: filter
    });
  }

  public indexCampaignAudits(id: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `first_touch_emails/${id}/audits`,
      hash: '',
      queryParams: filter
    });
  }

  public indexTemplatedEmailAudits(email: number, filter: object = null) {
    return buildUrl(this.apiURL, {
      path: `templated_emails/${email}/audits`,
      hash: '',
      queryParams: filter
    });
  }
}
