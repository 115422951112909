import {Component, AfterViewInit, OnInit} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.model';
import {ConfirmService} from '../../services/confirm.service';
import {environment} from '../../../environments/environment';
import {PusherService} from '../../services/pusher.service';
import {ToastrService} from 'ngx-toastr';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../services/users.service';
import {HgTourService} from '../../services/hg-tour.service';
import * as moment from 'moment';
import {WhiteLabelsService} from '../../services/white-labels.service';
import {AffiliateProgramService} from '../../services/affiliate-program.service';
import {HelperService} from '../../services/helper.service';
import {WhiteLabel} from '../../models/white-label.model';

declare global {
  interface Window {
    Beacon: any;
  }
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent implements OnInit, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};

  // user object
  auth: User;
  user: User;
  whiteLabel: WhiteLabel;

  logo: string;
  logoHorizontal: string;
  logoWhiteHorizontal: string;

  mergeCodes: any;
  wlUsers: User[];

  isLoadingAffiliateProgram = false;
  affiliatePromoter: any;
  affiliateLeadsAndCustomers: any;

  constructor(private confirmService: ConfirmService,
              private toastr: ToastrService,
              private pusherService: PusherService,
              private helperService: HelperService,
              private modalService: NgbModal,
              private affiliateService: AffiliateProgramService,
              private userService: UsersService,
              private wlService: WhiteLabelsService,
              private tourService: HgTourService,
              public router: Router) {
    this.auth = AuthService.getAuthUser();
    this.user = AuthService.getCurrentUser();
    this.whiteLabel = AuthService.getWhiteLabel();

    if (this.auth == null || this.user == null) {
      this.logout();
    }

    this.logo = this.whiteLabel.logo_white_small;
    this.logoHorizontal = this.whiteLabel.logo;
    this.logoWhiteHorizontal = this.whiteLabel.logo_white;
  }

  ngOnInit() {
    this.pusherService.subscribeUser(this.auth).bind('text_message.received', data => {
      this.showTextMessageToastNotification(
        data.contact_id,
        data.message,
        data.contact_name ? data.contact_name : 'New Message');
    });
    this.refreshMergeCodes();
  }

  ngAfterViewInit() {
    const set = function () {
      const width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      const mainWrapper = $('#main-wrapper');

      if (width < 1170) {
        mainWrapper.removeClass('max-sidebar').addClass('mini-sidebar');
      } else {
        mainWrapper.removeClass('mini-sidebar').addClass('max-sidebar');
      }
    };

    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on('click', function () {
      $('.app-search').toggle(200);
    });

    $('body').trigger('resize');
  }

  public logout() {
    this.confirmService.confirm({
      title: 'Confirm Log Out',
      messages: [
        'Are you sure you would like to log out?'
      ],
      yesText: 'Yes',
      yesType: 'danger',
      noText: 'No',
      noType: 'success',
    }).then(
      () => {
        console.log('deleting...');
        AuthService.logout();
      },
      () => {
        console.log('not deleting...');
      });
  }

  public showMergeCodes(modal) {
    this.modalService.open(modal, {
      size: 'lg',
      windowClass: environment.color
    });
  }

  public showWhiteLabelUsers(modal) {
    this.wlService.users(this.user.white_label_id).subscribe(
      success => {
        this.wlUsers = success;
      }
    );

    this.modalService.open(modal, {
      size: 'lg',
      windowClass: environment.color
    });
  }

  public refreshMergeCodes() {
    this.userService.mergeCodes(this.user.id).subscribe(
      success => {
        this.mergeCodes = success;
      },
      error => {

      }
    );
  }

  public showAffiliateProgram(modal) {
    this.isLoadingAffiliateProgram = true;

    this.getAffiliatePromoter();

    this.modalService.open(modal, {
      size: 'lg',
      windowClass: environment.color
    });
  }

  public getAffiliatePromoter() {
    this.affiliateService.showPromoter(this.user).subscribe(
      success => {
        this.affiliatePromoter = success;
        this.isLoadingAffiliateProgram = false;
      },
      error => {
        this.isLoadingAffiliateProgram = false;
      }
    );
  }

  public getLeadsAndCustomers() {
    this.affiliateService.showPromoter(this.user).subscribe(
      success => {
        this.affiliatePromoter = success;
        this.isLoadingAffiliateProgram = false;
        console.log(this.affiliatePromoter);
      },
      error => {
        this.isLoadingAffiliateProgram = false;
      }
    );
  }

  public joinAffiliateProgram() {
    this.isLoadingAffiliateProgram = true;
    this.affiliateService.joinProgram(this.user).subscribe(
      success => {
        this.affiliatePromoter = success;
        this.getAffiliatePromoter();
        this.toastr.success('You have successfully joined hgRevenue Share. Congratulations and thank you!');
      },
      error => {

      }
    );
  }

  public getEnvironmentColors() {
    return environment.colors;
  }

  private showTextMessageToastNotification(contact_id: number, message: string, title: string | null) {
    this.toastr.info(message, title, {
      timeOut: 5000,
      extendedTimeOut: 5000
    });
    // }).onTap
    //   .pipe(take(1))
    //   .subscribe(() => this.notificationClickedHandler(contact_id));
  }

  private notificationClickedHandler(contact_id: number) {
    const url = `#${this.router.createUrlTree(['/contacts', contact_id]).toString()}`;
    window.open(url, '_blank');
  }

  public stepTitle() {
    return this.tourService.stepTitle();
  }

  public stepText() {
    return this.tourService.stepText();
  }

  public copyText(input) {
    this.helperService.copyText(input);
  }
}
