import {Injectable} from '@angular/core';
import {ApiRouteService} from './api-route.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user.model';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {PaginatedUsers} from '../models/paginated-users.model';
import {Form} from '../models/form.model';
import {Signature} from '../models/signature.model';
import {PaginatedSignatures} from '../models/paginated-signatures.model';
import {BlastEmail} from '../models/blast-email.model';
import {PaginatedBlastEmails} from '../models/paginated-blast-emails.model';
import {PaginatedAudienceMessages} from '../models/paginated-audience-messages.model';
import {PaginatedAudienceEmails} from '../models/paginated-audience-emails.model';
import {AudienceEmail} from '../models/audience-email.model';
import {TemplatedEmail} from '../models/templated-email.model';
import {PaginatedTemplatedMessages} from '../models/paginated-templated-messages.model';
import {PaginatedTemplatedEmails} from '../models/paginated-templated-emails.model';
import {PaginatedSocialMessages} from '../models/paginated-social-messages.model';
import {Campaign} from '../models/campaign.model';
import {PaginatedCampaigns} from '../models/paginated-campaigns.model';
import {PaginatedNotes} from '../models/paginated-notes.model';
import {HotSheet} from '../models/hot-sheet.model';
import {PaginatedIntegrations} from '../models/paginated-integrations.model';
import {Integration} from '../models/integration.model';
import {PaginatedAddresses} from '../models/paginated-addresses.model';
import {buildUrl} from 'build-url';
import {Billing} from '../models/billing.model';
import {IntegrationTeamMembers} from '../models/integration-team-members.model';
import {IntegrationTags} from '../models/integration-tags.model';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private authURL: string;
  private apiURL: string;

  constructor(private apiURLs: ApiRouteService,
              private http: HttpClient) {
    this.authURL = environment.authURL;
    this.apiURL = environment.apiURL;
  }

  public index(filter: object) {
    return this.http.get<PaginatedUsers>(this.apiURLs.indexUsers(filter));
  }

  public view(user: number, queryParams: any = null) {
    // return this.http.get<User>(this.apiURLs.viewUser(user));
    return this.http.get<User>(buildUrl(this.apiURL, {
      path: `users/${user}`,
      hash: '',
      queryParams: queryParams
    }));
  }

  public update(user: number, attributes): Observable<User> {
    return this.http.put<User>(buildUrl(this.apiURL, {
      path: `users/${user}`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public updatePassword(user: number, attributes) {
    return this.http.put(buildUrl(environment.apiURL, {
      path: `users/${user}/password`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public delete(user: number) {
    return this.http.delete(buildUrl(this.apiURL, {
      path: `users/${user}`,
      hash: '',
      queryParams: null
    }));
  }

  public audits(user: number, filter: object = null) {
    return this.http.get<PaginatedUsers>(this.apiURLs.indexUserAudits(user, filter));
  }

  public notes(user: number, filter: object = null) {
    return this.http.get<PaginatedNotes>(this.apiURLs.indexUserNotes(user, filter));
  }

  public rolesAndPermissions(user: number) {
    return this.http.get(this.apiURLs.indexUserRolesAndPermissions(user));
  }

  public billingData(user: number, filter: object = null) {
    return this.http.get<Billing>(buildUrl(this.apiURL, {
        path: `users/${user}/billing`,
        hash: '',
        queryParams: filter
      })
    );
  }

  public reactivationAction(user: number) {
    return this.http.get(buildUrl(this.apiURL, {
      path: `users/${user}/reactivation`,
      hash: '',
      queryParams: null
    }));
  }

  public reactivateSubscription(user: number, attributes: any) {
    return this.http.post(buildUrl(this.apiURL, {
      path: `users/${user}/reactivation`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public updateForm(user: number) {
    return this.http.get<Form>(this.apiURLs.viewUserUpdateForm(user));
  }

  public defaultSignature(user: number) {
    return this.http.get<Signature>(this.apiURLs.userDefaultSignature(user));
  }

  public signatures(user: number) {
    return this.http.get<PaginatedSignatures>(this.apiURLs.userSignatures(user));
  }

  public addresses(user: number) {
    return this.http.get<PaginatedAddresses>(this.apiURLs.userAddresses(user));
  }

  public emailTemplate(user: number, type: string) {
    return this.http.get<Signature>(this.apiURLs.getUserEmailTemplate(user, type));
  }

  public filterRecipients(user: number, filter: object) {
    return this.http.get<object[]>(this.apiURLs.filterUserRecipients(user, filter));
  }

  public recipientsCount(user: number, params: object) {
    return this.http.get<any>(this.apiURLs.getRecipientsCount(user, params));
  }

  public createBlastEmail(user: number, params: object) {
    return this.http.post<BlastEmail>(this.apiURLs.postUserBlastEmail(user), params);
  }

  public createAudienceEmail(user: number, params: object) {
    return this.http.post<AudienceEmail>(this.apiURLs.postUserAudienceEmail(user), params);
  }

  public createTemplatedEmail(user: number, params: object) {
    return this.http.post<TemplatedEmail>(this.apiURLs.postUserTemplatedEmail(user), params);
  }

  public createCampaign(user: number, params: object) {
    return this.http.post<Campaign>(this.apiURLs.postUserCampaign(user), params);
  }

  public indexBlastEmails(user: number, params: object) {
    return this.http.get<PaginatedBlastEmails>(this.apiURLs.indexUserBlastEmails(user, params));
  }

  public indexAudienceEmails(user: number, params: object) {
    return this.http.get<PaginatedAudienceEmails>(this.apiURLs.indexUserAudienceEmails(user, params));
  }

  public indexTemplatedEmails(user: number, params: object) {
    return this.http.get<PaginatedTemplatedEmails>(this.apiURLs.indexUserTemplatedEmails(user, params));
  }

  public indexAudienceMessages(user: number, params: object) {
    return this.http.get<PaginatedAudienceMessages>(this.apiURLs.indexUserAudienceMessages(user, params));
  }

  public indexTemplatedMessages(user: number, params: object) {
    return this.http.get<PaginatedTemplatedMessages>(this.apiURLs.indexUserTemplatedMessages(user, params));
  }

  public indexSocialMessages(user: number, params: object) {
    return this.http.get<PaginatedSocialMessages>(this.apiURLs.indexUserSocialMessages(user, params));
  }

  public indexCampaigns(user: number, params: object) {
    return this.http.get<PaginatedCampaigns>(this.apiURLs.indexUserCampaigns(user, params));
  }

  public viewHotSheet(user: number, from: string, to: string) {
    return this.http.get<HotSheet>(buildUrl(environment.apiURL, {
      path: `users/${user}/hot_sheets/${from}/${to}`,
      hash: '',
      queryParams: null
    }));
  }

  public integrationTags(user: number, integration: number) {
    return this.http.get<IntegrationTags>(buildUrl(this.apiURL, {
      path: `users/${user}/integrations/${integration}/tags`,
      hash: '',
      queryParams: null
    }));
  }

  public integrationTeamMembers(user: number, integration: number) {
    return this.http.get<IntegrationTeamMembers>(buildUrl(this.apiURL, {
      path: `users/${user}/integrations/${integration}/team_members`,
      hash: '',
      queryParams: null
    }));
  }

  public integrations(user: number, attributes): Observable<PaginatedIntegrations> {
    return this.http.get<PaginatedIntegrations>(buildUrl(this.apiURL, {
      path: `users/${user}/integrations`,
      hash: '',
      queryParams: null
    }));
  }

  public integration(user: number, integration: number) {
    return this.http.get<Integration>(buildUrl(this.apiURL, {
      path: `users/${user}/integrations/${integration}`,
      hash: '',
      queryParams: null
    }));
  }

  public updateIntegration(user: number, attributes): Observable<Integration> {
    return this.http.put<Integration>(buildUrl(this.apiURL, {
      path: `users/${user}/integrations`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public getBillingPortalURl(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/billing/portal`,
      hash: '',
      queryParams: null
    }));
  }

  public createBillingCustomer(user: number, attributes) {
    return this.http.post(buildUrl(environment.apiURL, {
      path: `users/${user}/billing/customer`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  public createBillingSubscription(user: number, attributes) {
    return this.http.post(buildUrl(environment.apiURL, {
      path: `users/${user}/billing/subscription`,
      hash: '',
      queryParams: null
    }), attributes);
  }

  // ...

  public triggerCancelAccount(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/triggers/cancel`,
      hash: '',
      queryParams: null
    }));
  }

  public triggerReactivateAccount(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/triggers/reactivate`,
      hash: '',
      queryParams: null
    }));
  }

  public triggerLogOut(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/triggers/logout`,
      hash: '',
      queryParams: null
    }));
  }

  public triggerHotSheetEmail(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/triggers/email/hot_sheet`,
      hash: '',
      queryParams: null
    }));
  }

  public triggerWelcomeEmail(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/triggers/email/welcome`,
      hash: '',
      queryParams: null
    }));
  }

  public mergeCodes(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/merge_codes`,
      hash: '',
      queryParams: null
    }));

  }

  public accountProgress(user: number) {
    return this.http.get(buildUrl(environment.apiURL, {
      path: `users/${user}/account_progress`,
      hash: '',
      queryParams: null
    }));

  }
}
