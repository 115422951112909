import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // Dashboard
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Training Videos',
    path: '/training_videos',
    icon: 'mdi mdi-video',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['training_videos_feature'],
    submenu: [],
  },
  {
    title: 'Academies',
    path: '/academies',
    icon: 'mdi mdi-school',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['academies_feature'],
    submenu: [],
  },
  // Messaging
  {
    title: 'Messaging',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Email by Audience',
    path: '/audience_emails/library',
    icon: 'mdi mdi-account-multiple-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['audience_emails_feature'],
    submenu: [
      {
        title: 'Library',
        path: '/audience_emails/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['audience_emails_library'],
        submenu: [],
      },
      {
        title: 'Scheduled & Saved',
        path: '/audience_emails/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/audience_emails/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Email by Message Type',
    path: '/templated_emails/library',
    icon: 'mdi mdi-comment-text-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['message_type_feature'],
    submenu: [
      {
        title: 'Library',
        path: '/templated_emails/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['message_type_library'],
        submenu: [],
      },
      {
        title: 'Scheduled & Saved',
        path: '/templated_emails/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/templated_emails/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Social Media Content',
    path: '/social_messages/library',
    icon: 'mdi mdi-share-variant',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['social_messages_feature'],
    submenu: [],
  },
  {
    title: 'Automated Campaigns',
    path: '/campaigns/library',
    icon: 'mdi mdi-account-convert',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['campaigns_feature'],
    submenu: [
      {
        title: 'Library',
        path: '/campaigns/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['campaigns_library'],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/campaigns/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Write Your Own',
    path: '/blasts/scheduled',
    icon: 'mdi mdi-meteor',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['blast_emails_feature'],
    submenu: [
      {
        title: 'Scheduled & Saved',
        path: '/blasts/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/blasts/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Voicemail Drops',
    path: '/voicemails/index',
    icon: 'mdi mdi-voicemail',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['voicemail_drops_feature'],
    submenu: [
      {
        title: 'Scheduled & Saved',
        path: '/voicemails/index',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/voicemails/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ],
  },
  {
    title: 'Text Messaging',
    path: '/text_messages/library',
    icon: 'mdi mdi-cellphone',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['text_messaging_feature'],
    submenu: [
      {
        title: 'Library',
        path: '/text_messages/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['text_messaging_library'],
        submenu: [],
      },
      {
        title: 'Scheduled & Saved',
        path: '/text_messages/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['text_messaging_scheduled'],
        submenu: [],
      },
      {
        title: 'Channels',
        path: '/text_messages/channels',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['text_messaging_channels'],
        submenu: [],
      },
      {
        title: 'Phone Numbers',
        path: '/text_messages/phone_numbers',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['text_messaging_phone_numbers'],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/text_messages/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Handwritten Cards',
    path: '/cards/scheduled',
    icon: 'mdi mdi-pen',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['cards_feature'],
    submenu: [
      {
        title: 'Scheduled & Saved',
        path: '/cards/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Custom Covers',
        path: '/cards/covers',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['cards_custom'],
        submenu: [],
      },
      {
        title: 'Library',
        path: '/cards/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Reporting',
        path: '/cards/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['reporting_feature'],
        submenu: [],
      },
    ],
  },
  {
    title: 'Artisan Gifts',
    path: '/gifts/scheduled',
    icon: 'mdi mdi-gift',
    class: 'has-arrow',
    label: 'new',
    labelClass: 'badge badge-success ml-2',
    extralink: false,
    permissions: ['gifts_feature'],
    submenu: [
      {
        title: 'Scheduled & Saved',
        path: '/gifts/scheduled',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      /*{
        title: 'Library',
        path: '/gifts/library',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },*/
      /*{
        title: 'Reporting',
        path: '/gifts/history',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },*/
    ],
  },
  // Management
  {
    title: 'Management',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Contacts',
    path: '/contacts/index',
    icon: 'mdi mdi-account-multiple-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['contacts_feature'],
    submenu: [
      {
        title: 'Index',
        path: '/contacts/index',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Upload',
        path: '/contacts/upload',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['contacts_create'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Tags',
    path: '/tags/index',
    icon: 'mdi mdi-tag-outline',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['tags_feature'],
    submenu: []
  },
  {
    title: 'Audiences',
    path: '/audiences/index',
    icon: 'mdi mdi-tag',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['audiences_feature'],
    submenu: []
  },
  {
    title: 'Signatures',
    path: '/signatures/index',
    icon: 'mdi mdi-account-card-details',
    class: 'has-arrow',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['signatures_feature'],
    submenu: [
      {
        title: 'View Signatures',
        path: '/signatures/index',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
      {
        title: 'Signature Builder',
        path: '/signatures/builder',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['signatures_templated_create'],
        submenu: [],
      },
      {
        title: 'Custom Builder',
        path: '/signatures/custom',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: ['signatures_custom_create'],
        submenu: [],
      },
    ]
  },
  {
    title: 'Custom Fields',
    path: '/fields/index',
    icon: 'mdi mdi-view-day',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['custom_fields_feature'],
    submenu: [],
  },
  {
    title: 'Custom Forms',
    path: '/forms/index',
    icon: 'mdi mdi-view-headline',
    class: '',
    label: '',
    labelClass: 'badge badge-success text-white',
    extralink: false,
    permissions: ['custom_forms_feature'],
    submenu: [],
  },
  {
    title: 'Digital Business Card',
    path: '/sites',
    icon: 'mdi mdi-cards',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['sites_feature'],
    submenu: [],
  },
  // Misc.
  {
    title: 'Misc.',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Reporting',
    path: '/reporting',
    icon: 'mdi mdi-numeric',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['reporting_feature'],
    submenu: [],
  },
  {
    title: 'Hot Sheets',
    path: '/hot_sheets',
    icon: 'mdi mdi-fire',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['hot_sheets_feature'],
    submenu: [],
  },
  {
    title: 'Webinars',
    path: '/webinars',
    icon: 'mdi mdi-video',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['webinars_feature'],
    submenu: [],
  },
  {
    title: 'Integrations',
    path: '/integrations',
    icon: 'mdi mdi-lan-connect',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['integrations_feature'],
    submenu: [],
  },
  {
    title: 'Settings / Profile',
    path: '/users/me',
    icon: 'mdi mdi-settings',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Billing',
    path: '',
    icon: 'mdi mdi-receipt',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: ['misc_billing_feature'],
    submenu: [],
    action: function () {
      return 'billing';
    }
  },
  {
    title: 'Support',
    path: '/support',
    icon: 'mdi mdi-headset',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Log Out',
    path: '',
    icon: 'mdi mdi-power',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
    action: function () {
      return 'logout';
    }
  },
];
