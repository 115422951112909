import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import {HttpClient} from '@angular/common/http';
import {buildUrl} from 'build-url';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AffiliateProgramService {
  private apiURL: string;

  constructor(private http: HttpClient) {
    this.apiURL = environment.apiURL;
  }

  public showPromoter(user: User) {
    return this.http.get(buildUrl(this.apiURL, {
      path: `users/${user.id}/affiliate_program`,
      hash: '',
      queryParams: null
    }));
  }

  public joinProgram(user: User) {
    return this.http.post(buildUrl(this.apiURL, {
      path: `users/${user.id}/affiliate_program`,
      hash: '',
      queryParams: null
    }), {});
  }
}
