const authURL = 'https://myauth.happygrasshopper.com';
const apiURL = 'https://myauth.happygrasshopper.com/v3';

export const environment = {
  production: false,

  authURL: authURL,
  apiURL: apiURL,

  color: 'happyghopper',
  colors: {
    /*Bootstrap Color*/
    primary: '#6fbd46',
    danger: '#dc3545',
    warning: '#ffc107',
    success: '#28a745',
    info: '#1976d2',
    /*Normal Color*/
    white: '#ffffff',
    red: '#fb3a3a',
    yellow: '#ffc107',
    purple: '#7460ee',
    blue: '#1976d2',
    green: '#28a745',
    orange: '#fd7e14',
  },

  logo: 'https://cdn.happygrasshopper.com/assets/hg.png',
  logoHorizontal: 'https://cdn.happygrasshopper.com/assets/logo-horizontal.png',

  logoWhite: 'https://cdn.happygrasshopper.com/assets/hg-white.png',
  logoWhiteHorizontal: 'https://cdn.happygrasshopper.com/assets/logo-white-horizontal.png',

  faviconLocation: 'assets/images/favicon.ico',

  showPoweredBy: true,

  pusher: {
    key: 'b64eace8464df12282f6',
    cluster: 'us2',
  },

  stripe: {
    key: 'pk_live_kdHpvLGUmZQ6uKm86KICIg8k',
  },

  lion_desk: {
    client_id: 'be5b6b1b7252dc921a5473896ccb5b8660b10c6c'
  },

  chime: {
    client_id: 'b6v98xd0m60w'
  }
};
