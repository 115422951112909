import {Component, OnInit} from '@angular/core';
import {ROUTES} from './menu-items';
import {TEAM_ADMIN_ROUTES} from './team-menu-items';
import {ADMIN_ROUTES} from './admin-menu-items';
import {User} from '../../models/user.model';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UsersService} from '../../services/users.service';
import {ConfirmService} from '../../services/confirm.service';
import {ToastrService} from 'ngx-toastr';
import {HgTourService} from '../../services/hg-tour.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  user: User;

  showMenu = '';
  showSubMenu = '';

  public sidebarnavItems: any[];

  constructor(private authService: AuthService,
              private userService: UsersService,
              private confirmService: ConfirmService,
              private toastr: ToastrService,
              private tourService: HgTourService,
              private router: Router) {
    this.user = AuthService.getCurrentUser();
  }

  // End openAddModal close
  ngOnInit() {
    this.setSidebarNavItems();

    $(function () {
      $('.sidebartoggler').on('click', function () {
        const mainWrapper = $('#main-wrapper');

        if (mainWrapper.hasClass('mini-sidebar')) {
          $('body').trigger('resize');
          mainWrapper.removeClass('mini-sidebar').addClass('max-sidebar');
        } else {
          $('body').trigger('resize');
          mainWrapper.addClass('mini-sidebar').removeClass('max-sidebar');
        }
      });
    });
  }

  // is for the openAddModal close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  doAction(action) {
    switch (action) {
      case 'billing':
        this.redirectBillingUrl();
        break;
      case 'logout':
        this.confirmService.confirm({
          title: 'Confirm Log Out',
          messages: [
            'Are you sure you would like to log out?'
          ],
          yesText: 'Yes',
          yesType: 'danger',
          noText: 'No',
          noType: 'success',
        }).then(
          () => {
            console.log('deleting...');

            AuthService.logout();
          },
          () => {
            console.log('not deleting...');
          });
        break;
      default:
        return;
    }
  }

  public stepTitle() {
    return this.tourService.stepTitle();
  }

  public stepText() {
    return this.tourService.stepText();
  }

  private redirectBillingUrl() {
    this.userService.getBillingPortalURl(this.user.id).subscribe(response => {
      if (response['url'] != null) {
        window.open(response['url'], '_blank');
      } else {
        this.toastr.error('No billing record is associated with this account.');
      }
    });
  }

  private setSidebarNavItems() {
    if (this.router.url.lastIndexOf('/admin/', 0) === 0) {
      this.sidebarnavItems = ADMIN_ROUTES.filter(sidebarnavItem => sidebarnavItem);
      return;
    }

    if (this.router.url.lastIndexOf('/teams/', 0) === 0) {
      const teamId = this.router.url.split('/')[2]; // extract from url

      const items = TEAM_ADMIN_ROUTES.filter(sidebarnavItem => sidebarnavItem);

      this.sidebarnavItems = [];

      items.forEach(x => {
        x.path = x.path.replace(':teamId', teamId);
        x.submenu.forEach(y => y.path = y.path.replace(':teamId', teamId));

        if (x.permissions.length === 0) { // route doesn't require special permissions
          this.sidebarnavItems.push(x);
          return;
        }

        if (this.user.hasAnyPermission(x.permissions)) {
          this.sidebarnavItems.push(x);
        }
      });

      // this.sidebarnavItems = items;
      return;
    }

    this.sidebarnavItems = [];
    ROUTES.forEach(route => {
      if (route.permissions.length === 0) { // route doesn't require special permissions
        this.sidebarnavItems.push(route);
        return;
      }

      // submenu
      if (route.submenu.length > 0) {
        const submenu = [];

        route.submenu.forEach(subRoute => {
          if (subRoute.permissions.length === 0) {
            submenu.push(subRoute);
            return;
          }

          if (this.user.hasAnyPermission(subRoute.permissions)) {
            submenu.push(subRoute);
          }
        });

        route.submenu = submenu;
      }

      if (this.user.hasAnyPermission(route.permissions)) {
        this.sidebarnavItems.push(route);
      }
    });
  }
}
