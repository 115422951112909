import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector:    'app-action-btn',
  templateUrl: './action-btn.component.html',
  styleUrls:   ['./action-btn.component.css']
})

export class ActionBtnComponent implements OnInit {
  @Input() action = 'Save';
  @Input() working = 'Working';
  @Input() type = 'success';
  @Input() position = 'right';
  @Input() size = 'md';
  @Input() addCss = '';
  @Input() loading: boolean;
  @Input() disabled = false;

  @Output() clicked = new EventEmitter<any>();

  constructor() {
    this.loading = false;
  }

  ngOnInit() {
  }

  click() {
    if (this.loading) {
      return;
    }

    this.clicked.emit({});
  }

}
