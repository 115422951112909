import {RouteInfo} from './sidebar.metadata';

export const ADMIN_ROUTES: RouteInfo[] = [
  {
    title: 'Dashboard',
    path: '/admin/dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  // Management
  {
    title: 'Management',
    path: '',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Users',
    path: '/admin/users',
    icon: 'mdi mdi-account-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Teams',
    path: '/admin/teams',
    icon: 'mdi mdi-account-multiple-plus',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Training Videos',
    path: '/admin/training_videos',
    icon: 'mdi mdi-video',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Plans',
    path: '/admin/plans',
    icon: 'mdi mdi-clipboard-account',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'White Labels',
    path: '/admin/sites',
    icon: 'mdi mdi-label',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Messages',
    path: '/admin/messages',
    icon: 'mdi mdi-email',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [
      {
        title: 'Queen Bee Reporting',
        path: '/admin/messages/reporting',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        permissions: [],
        submenu: [],
      },
    ],
  },
  {
    title: 'Application Tours',
    path: '/admin/tours',
    icon: 'mdi mdi-directions',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'User Tasks',
    path: '/admin/tasks',
    icon: 'mdi mdi-checkbox-multiple-marked',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Holidays',
    path: '/admin/holidays',
    icon: 'mdi mdi-cake',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'Academy',
    path: '/admin/academies',
    icon: 'mdi mdi-school',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
  {
    title: 'User Health',
    path: '/admin/user_health',
    icon: 'mdi mdi-account-alert',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    permissions: [],
    submenu: [],
  },
];
