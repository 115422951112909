import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../models/contact.model';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
})

export class ProfileImageComponent implements OnInit {
  @Input() client: User | Contact;
  @Input() size: number;
  @Input() border = '#c5c5c5';

  constructor() {
  }

  ngOnInit() {
  }

  public getName(input: User | Contact) {
    if (!input) {
      return '';
    }

    if (input.first_name && input.last_name) {
      return `${input.first_name[0]} ${input.last_name[0]}`;
    }

    if (input.first_name && !input.last_name) {
      return `${input.first_name[0]}`;
    }

    if (!input.first_name && input.last_name) {
      return `${input.last_name[0]}`;
    }

    return '';
  }
}
